<template>
  <v-container class="edit-container px-10" fluid>
    <v-alert-confirm
      t-data="cancel-popup"
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></v-alert-confirm>
    <v-popup-loading :isLoading="isLoading" />
    <v-alert-error
      :show="isError"
      :close="closeAlert"
      msg="Incomplete information"
    ></v-alert-error>

    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row
          class="align-center"
          style="margin-left: 1px; margin-right: 0px;"
        >
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} Sub Package Group
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="false"
            :show-preview="false"
            :show-save="!disabledEdit"
            :on-remove="showDeletePopup"
            :on-cancel="showCancelPopup"
            :on-save="save"
          />
        </v-row>
      </v-col>
    </v-row>

    <v-card t-data="package-info">
      <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
        <h6 class="mb-0 card-title" t-data="info-title">
          Sub Package Group Information
        </h6>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-0">
        <v-row
          v-if="disabledEdit && !isLoading"
          align="start"
          justify="start"
          class="mt-0"
        >
          <v-col cols="12" class="py-0">
            <p class="body-bold error-content mb-6" t-data="warning-content">
              You cannot edit information because you do not permission to edit
              package
            </p>
          </v-col>
        </v-row>
        <v-form ref="form" lazy-validation>
          <v-row align="start" justify="start" class="mt-0 mb-4">
            <v-col cols="6" class="mt-0 pt-0">
              <v-row class="mb-4">
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Centrix Code <span class="danger500Color--text">*</span>
                  </p>
                  <v-text-field
                    outlined
                    required
                    dense
                    class="subtitle-2 can-disable-selector"
                    v-model="subPackageObject.centrix_code"
                    placeholder="Enter Centrix Code of Product"
                    :rules="[centrixCodeRules.required]"
                    :disabled="disabledEdit"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Product List Price
                    <span class="danger500Color--text">*</span>
                  </p>
                  <v-text-field
                    id="product-list-price-text-box"
                    outlined
                    dense
                    required
                    :rules="[fullPriceRules.required, fullPriceRules.valid]"
                    @keypress="isNumber"
                    class="subtitle-2 can-disable-selector"
                    onkeypress="if(this.value.length==9) return false;"
                    v-model="fullPrice"
                    placeholder="Product List Price (Baht)"
                    @input="fullPriceInputed"
                    :disabled="disabledEdit"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Discount Type
                  </p>
                  <v-select
                    outlined
                    dense
                    class="can-disable-selector"
                    v-model="subPackageObject.discount_type"
                    :menu-props="{bottom: true, offsetY: true}"
                    :items="discountTypes"
                    item-value="value"
                    item-text="label"
                    @change="changedDiscountType"
                    :disabled="disabledEdit"
                  ></v-select>
                </v-col>
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Value
                  </p>
                  <v-text-field
                    class="can-disable-selector"
                    id="value-text-box"
                    :hide-details="showWarningDiscount"
                    @blur="blurDiscountValueError"
                    @update:error="setDiscountValueError"
                    outlined
                    required
                    dense
                    @keypress="isNumber"
                    :rules="
                      subPackageObject.discount_type === 'NO_DISCOUNT'
                        ? []
                        : [
                            discountValueRules.lessThanOrEqualZero,
                            discountValueRules.required
                          ]
                    "
                    :prefix="discountTypePrefix"
                    :disabled="isDisableDiscountType || disabledEdit"
                    v-model="subPackageObject.discount"
                    @change="discountChange"
                  ></v-text-field>
                  <v-row
                    v-if="showWarningDiscount"
                    align="center"
                    justify="start"
                    class="flex-nowrap"
                    no-gutters
                  >
                    <v-img
                      aspect-ratio="1"
                      class="value-message-icon mt-0 mr-1 ml-1"
                      max-height="16px"
                      max-width="16px"
                      src="/images/icons/info-icon.png"
                    ></v-img>
                    <p
                      class="value-message secondaryTextColor--text small mb-0"
                    >
                      {{ discountIsZeroErrorMessage }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <div
              class="mx-auto pt-0"
            >
              <sale-price-preview
                :is-show-price-first-create="isShowPriceFirstCreate"
                :full-price="subPackageObject.full_price"
                :discount="isDisableDiscountType ? 0 : discountValue"
                :discount-type="subPackageObject.discount_type"
                @error="e => (isPricePreviewError = e)"
              />
            </div>
          </v-row>

          <!-- Contents -->
          <v-card class="border-card mt-6 mb-4">
            <v-card-text>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6">
                  <h6 class="mb-6 card-title" t-data="thai-info-header">
                    Thai Sub Package Group
                  </h6>
                </v-col>
                <v-col cols="2">
                  <h6 class="mb-6 card-title" t-data="eng-info-header">
                    Eng Sub Package Group
                  </h6>
                </v-col>
                <v-col t-data="checkbox-duplicate">
                  <v-checkbox
                    v-model="duplicateContent"
                    :disabled="disabledEdit"
                    hide-details
                    label="Duplicate Thai content"
                    color="secondary"
                    class="check-box-text body-regular mt-0 pt-0"
                    @click="duplicateThaiContent"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0">
                <v-col cols="6" t-data="thai-info-content">
                  <content-by-lang
                    class="px-0 ml-3"
                    lang="th"
                    :title="subPackageObject.contents[0].title"
                    :url="subPackageObject.contents[0].thumbnail"
                    :description.sync="subPackageObject.contents[0].description"
                    :disabledEdit="disabledEdit"
                    :setImage="setImage"
                    :setImageError="setImageError"
                    :isImageError="isImageThError"
                    :imageErrorMessage="imageThErrorMessage"
                    :show-description="true"
                    :required-title="true"
                    :required-image="true"
                    image-field-name="Sub Package Image"
                    title-field-name="Sub Package Title"
                    title-placeholder="Input Sub Package Title"
                    cols-image="12"
                    cols-title="12"
                    order-image="2"
                    order-title="1"
                    @changeUrl="changeUrlTh"
                    @changeTitle="changeTitleTh"
                    @changeDescription="changeDescriptionTh"
                    @removeImage="removeImageTh"
                  >
                    <template v-slot:thumbnail-alt>
                      <v-col
                        v-show="isWeb"
                        order="3"
                        cols="12"
                        t-data="info-input"
                        class="mt-4"
                      >
                        <p
                          class="edit-field-label sub-body-bold"
                          t-data="info-label"
                        >
                          Alt Text
                        </p>
                        <v-text-field
                          class="can-disable-selector"
                          placeholder="Alt Text"
                          outlined
                          dense
                          required
                          :disabled="disabledEdit"
                          v-model="subPackageObject.contents[0].thumbnail_alt"
                          @input="changeAltTh"
                        ></v-text-field>
                      </v-col>
                    </template>
                  </content-by-lang>
                </v-col>
                <v-col cols="6" t-data="eng-info-content">
                  <content-by-lang
                    class="px-0"
                    lang="en"
                    :title="subPackageObject.contents[1].title"
                    :url="subPackageObject.contents[1].thumbnail"
                    :description.sync="subPackageObject.contents[1].description"
                    :disabledEdit="disabledEdit"
                    :setImage="setImage"
                    :setImageError="setImageError"
                    :isImageError="isImageEnError"
                    :imageErrorMessage="imageEnErrorMessage"
                    :show-description="true"
                    :required-title="true"
                    :required-image="true"
                    image-field-name="Sub Package Image"
                    title-field-name="Sub Package Title"
                    title-placeholder="Input Sub Package Title"
                    cols-image="12"
                    cols-title="12"
                    order-image="2"
                    order-title="1"
                    @changeTitle="changeTitleEn"
                    @removeImage="removeImageEn"
                  >
                    <template v-slot:thumbnail-alt>
                      <v-col
                        v-show="isWeb"
                        cols="12"
                        t-data="info-input"
                        class="mt-4"
                        order="3"
                      >
                        <p
                          class="edit-field-label sub-body-bold"
                          t-data="info-label"
                        >
                          Alt Text
                        </p>
                        <v-text-field
                          class="can-disable-selector"
                          placeholder="Alt Text"
                          outlined
                          dense
                          required
                          :disabled="disabledEdit"
                          v-model="subPackageObject.contents[1].thumbnail_alt"
                        ></v-text-field>
                      </v-col>
                    </template>
                  </content-by-lang>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
    </v-card>
    <v-row
      class="d-flex align-start mb-3"
      t-data="footer-row"
      style="margin-left: 1px; margin-right: 0;"
    >
      <v-spacer></v-spacer>
      <edit-buttons
        class="mt-3"
        :show-remove="false"
        :show-preview="false"
        :show-save="!disabledEdit"
        :on-remove="showDeletePopup"
        :on-cancel="showCancelPopup"
        :on-save="save"
      />
    </v-row>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
  isUrl,
  convertIntegerToCommaNumber,
  hasPermissionsByOrgs,
  convertCommaNumberToInteger
} from '../../helper/helper'
import {mapState, mapActions} from 'vuex'
import PopupLoading from '../../components/PopupLoading'
import AlertError from '../../components/AlertError'
import {
  discountTypeText,
  discountTypeEnum,
  packageTypeText,
  displayTypeEnum
} from '@/constants/package'
import EditButtons from '@/components/EditButtons'
import AlertConfirm from '@/components/AlertConfirm'
import ContentByLang from '@/components/ContentByLang'
import SalePricePreview from '@/components/package/SalePricePreview'

export default {
  components: {
    'v-alert-confirm': AlertConfirm,
    'v-alert-error': AlertError,
    'v-popup-loading': PopupLoading,
    ContentByLang,
    EditButtons,
    SalePricePreview
  },
  data() {
    return {
      isEditMode: false,
      isShowPriceFirstCreate: true,
      isPricePreviewError: false,
      duplicateContent: false,
      discountValue: null,
      isError: false,
      discountIsZeroErrorMessage: 'Input value more than 0',
      isCancel: false,
      isFullPriceError: false,
      isDiscountError: false,
      isCentrixCodeError: false,
      fullPriceRules: {
        required: value => {
          if (value) {
            this.isFullPriceError = false
            return true
          }
          this.isFullPriceError = true
          return 'Please enter price.'
        },
        valid: value => {
          if (!value) {
            this.isFullPriceError = true
            return 'Please enter price.'
          }
          if (!value && convertCommaNumberToInteger(value) <= 0) {
            this.isFullPriceError = true
            return `Full price must be greater than 0`
          }

          return true
        }
      },
      discountValueRules: {
        required: value => {
          if (value) {
            this.isDiscountError = false
            return true
          }
          this.isDiscountError = true
          return 'Value is required'
        },
        lessThanOrEqualZero: value => {
          if (parseInt(value) <= 0) {
            this.isDiscountError = true
            return this.discountIsZeroErrorMessage
          }
          return true
        }
      },
      centrixCodeRules: {
        required: value => {
          if (value) {
            this.isCentrixCodeError = false
            return true
          }
          this.isCentrixCodeError = true
          return 'Centrix Code is required'
        }
      }
    }
  },
  destroyed() {
    this.resetPackageState()
    this.resetState()
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetPackageState()
    this.resetState()
  },
  async created() {
    this.setIsLoading(true)
    this.isEditMode = !!this.$route.params.id
    this.setEditMode(this.isEditMode)
    if (this.isEditMode) {
      this.scrollToTop()
      this.isShowPriceFirstCreate = false
      await this.setSubpackageId(this.$route.params.id)
      await this.setPackage(this.subPackageObject.package_id)
      this.discountValue = this.subPackageObject.discount
    } else {
      await this.setPackage(this.subPackageObject.package_id)
    }

    const isViewOnly =
      this.isEditMode &&
      !hasPermissionsByOrgs('CanEditPackage', this.packageObject.orgs)
    await this.setOrgInfo(isViewOnly)
    this.setIsLoading(false)
  },
  computed: {
    isWeb() {
      return (
        this.packageObject.display_type === displayTypeEnum.ALL ||
        this.packageObject.display_type === displayTypeEnum.WEB
      )
    },
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditPackage', this.packageObject.orgs)
      )
    },
    showWarningDiscount() {
      return (
        this.subPackageObject.discount_type !== discountTypeEnum.NO_DISCOUNT &&
        !this.isDiscountError
      )
    },
    packageTypes() {
      const types = []
      for (let key in packageTypeText) {
        if (packageTypeText.hasOwnProperty(key)) {
          types.push({label: packageTypeText[key], value: key})
        }
      }
      return types
    },
    discountTypes() {
      const types = []
      for (let key in discountTypeText) {
        if (discountTypeText.hasOwnProperty(key)) {
          types.push({label: discountTypeText[key], value: key})
        }
      }
      return types
    },
    isDisableDiscountType() {
      return (
        this.subPackageObject.discount_type === discountTypeEnum.NO_DISCOUNT
      )
    },
    discountTypePrefix() {
      if (this.subPackageObject.discount_type === discountTypeEnum.PRICE) {
        return '฿'
      } else if (
        this.subPackageObject.discount_type === discountTypeEnum.PERCENTAGE
      ) {
        return '%'
      } else {
        return ''
      }
    },
    fullPrice: {
      get() {
        const commaNum = convertIntegerToCommaNumber(
          this.subPackageObject.full_price
        )
        return commaNum === '0' ? '' : commaNum
      },
      set(value) {
        if (
          value !== '' &&
          Number.isInteger(convertCommaNumberToInteger(value))
        ) {
          this.subPackageObject.full_price = convertCommaNumberToInteger(value)
        }
      }
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Add'
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Package & Promotion', links: true, to: '/packages'},
        {
          text: `Edit Package`,
          links: true,
          to: `/package/${this.subPackageObject.package_id}`
        },
        {text: `${this.textMode} Sub Package Group`, links: false}
      ]
    },
    ...mapState('packages', ['packageObject']),
    ...mapState('subPackage', [
      'subPackageObject',
      'imageEnErrorMessage',
      'imageThErrorMessage',
      'isImageEnError',
      'isImageThError',
      'isLoading'
    ])
  },
  methods: {
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({
        name: 'package-edit',
        params: {id: this.subPackageObject.package_id}
      })
    },
    changedDiscountType() {
      if (
        this.subPackageObject.discount_type === discountTypeEnum.NO_DISCOUNT
      ) {
        this.subPackageObject.discount = null
      } else if (this.discountValue !== null) {
        this.subPackageObject.discount = this.discountValue
      }
    },
    changeAltTh() {
      if (this.duplicateContent && this.isWeb) {
        this.subPackageObject.contents[1].thumbnail_alt = this.subPackageObject.contents[0].thumbnail_alt
      }
    },
    fullPriceInputed(value) {
      this.fullPrice = value == '' ? 0 : value
      this.isShowPriceFirstCreate = false
    },
    branchRequired(value) {
      if (value instanceof Array && value.length == 0) {
        return 'Branch is required.'
      }
      return !!value || 'Branch is required.'
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    showDeletePopup() {
      this.setDeleteId(this.subPackageObject.id)
      this.setDeletePopup(true)
    },
    changeUrlTh(val) {
      if (this.duplicateContent) {
        this.setImage({lang: 'en', file: val})
        this.setImageError({lang: 'en', isError: false, message: ''})
      }
    },
    changeTitleTh(val) {
      this.subPackageObject.contents[0].title = val
      if (this.duplicateContent) {
        this.subPackageObject.contents[1].title = val
      }
    },
    changeDescriptionTh(val) {
      this.subPackageObject.contents[0].description = val
      if (this.duplicateContent) {
        this.subPackageObject.contents[1].description = val
      }
    },
    removeImageTh() {
      this.subPackageObject.contents[0].thumbnail = ''
      if (this.duplicateContent) {
        this.subPackageObject.contents[1].thumbnail = ''
        this.imageRules(
          this.subPackageObject.contents[1].code,
          this.subPackageObject.contents[1].thumbnail
        )
      }
    },
    changeTitleEn(val) {
      this.subPackageObject.contents[1].title = val
    },
    removeImageEn() {
      this.subPackageObject.contents[1].thumbnail = ''
    },
    duplicateThaiContent() {
      if (this.duplicateContent) {
        this.subPackageObject.contents[1].title = this.subPackageObject.contents[0].title
        this.subPackageObject.contents[1].description = this.subPackageObject.contents[0].description
        this.subPackageObject.contents[1].thumbnail = this.subPackageObject.contents[0].thumbnail
        if (this.isWeb) {
          this.subPackageObject.contents[1].thumbnail_alt = this.subPackageObject.contents[0].thumbnail_alt
        }
      } else {
        this.subPackageObject.contents[1].title = null
        this.subPackageObject.contents[1].description = ''
        this.subPackageObject.contents[1].thumbnail = ''
        if (this.isWeb) {
          this.subPackageObject.contents[1].thumbnail_alt = ''
        }
      }
      this.imageRules(
        this.subPackageObject.contents[1].code,
        this.subPackageObject.contents[1].thumbnail
      )
    },
    duplicateThaiContentChange() {
      if (this.duplicateContent) {
        this.subPackageObject.contents[1].title = this.subPackageObject.contents[0].title
        this.subPackageObject.contents[1].description = this.subPackageObject.contents[0].description
        this.subPackageObject.contents[1].thumbnail = this.subPackageObject.contents[0].thumbnail
        if (this.isWeb) {
          this.subPackageObject.contents[1].thumbnail_alt = this.subPackageObject.contents[0].thumbnail_alt
        }
      }
    },
    convertNumToComma(value) {
      return convertIntegerToCommaNumber(value)
    },
    isNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault()
      }
    },
    discountChange(event) {
      this.discountValue = this.subPackageObject.discount

      if (event != '' && Number.isInteger(parseInt(event))) {
        this.subPackageObject.discount = Math.floor(parseInt(event))
        this.discountValue = this.subPackageObject.discount
        return
      }
    },
    setIsErrorDiscountValue() {
      if (
        this.subPackageObject.discount === null &&
        this.subPackageObject.discount_type !== discountTypeEnum.NO_DISCOUNT
      ) {
        this.isDiscountError = true
      }
    },
    blurDiscountValueError() {
      this.setIsErrorDiscountValue()
    },
    setDiscountValueError(value) {
      this.isDiscountError = value
    },
    imageRules(lang, val) {
      if (this.isEditMode) {
        this.setImageError({lang, isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.setImageError({
          lang,
          isError: true,
          message: 'Image is required'
        })
        return
      } else {
        if (!val || val === '') {
          this.setImageError({
            lang,
            isError: true,
            message: 'Image is required'
          })
          return
        }
        this.setImageError({lang, isError: false, message: ''})
        return
      }
    },
    closeAlert() {
      this.isError = false
    },
    async save() {
      this.setIsErrorDiscountValue()
      if (!this.isDisabledPreview()) {
        if (this.validate()) {
          this.setStyleImage()

          if (!this.isEditMode) {
            let status = await this.create(this.subPackageObject)
            if (status) {
              this.$router.push({
                name: 'package-edit',
                query: {isCreateSuccess: true, snackTitle: 'created'},
                params: {id: this.subPackageObject.package_id}
              })
            }
            return (this.isError = true)
          }
          if (this.isEditMode) {
            const payload = {
              pkg: this.subPackageObject,
              id: this.$route.params.id
            }
            let status = await this.update(payload)
            if (status) {
              this.scrollToTop()
              this.$router.push({
                name: 'package-edit',
                query: {isCreateSuccess: true, snackTitle: 'edited'},
                params: {id: this.subPackageObject.package_id}
              })
            }
            this.scrollToTop()
            return (this.isError = true)
          }
        }
      } else {
        this.$refs.form.validate()
        await this.imageRules(
          this.subPackageObject.contents[0].code,
          this.subPackageObject.contents[0].thumbnail
        )
        await this.imageRules(
          this.subPackageObject.contents[1].code,
          this.subPackageObject.contents[1].thumbnail
        )
      }
    },
    isDisabledPreview() {
      if (
        this.isImageThError ||
        this.isImageEnError ||
        this.subPackageObject.contents[0].title === null ||
        this.subPackageObject.contents[0].title === '' ||
        this.subPackageObject.contents[1].title === null ||
        this.subPackageObject.contents[1].title === '' ||
        this.subPackageObject.contents[0].thumbnail === '' ||
        this.subPackageObject.contents[1].thumbnail === '' ||
        this.isPricePreviewError ||
        this.isFullPriceError ||
        this.isDiscountError ||
        this.isCentrixCodeError
      ) {
        return true
      } else {
        return false
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        return true
      }

      await this.imageRules(
        this.subPackageObject.contents[0].code,
        this.subPackageObject.contents[0].thumbnail
      )
      await this.imageRules(
        this.subPackageObject.contents[1].code,
        this.subPackageObject.contents[1].thumbnail
      )
    },
    ...mapActions('packages', {
      resetPackageState: 'resetState',
      setPackage: 'setPackageId'
    }),
    ...mapActions('subPackage', [
      'create',
      'delete',
      'resetState',
      'setDeleteId',
      'setDeletePopup',
      'setEditMode',
      'setImage',
      'setImageError',
      'setIsLoading',
      'setOrgInfo',
      'setPackageId',
      'setStyleImage',
      'setSubpackageId',
      'update',
      'upload'
    ])
  }
}
</script>

<style scoped>
.value-message-icon {
  height: 16px !important;
  width: 16px !important;
}

.value-message {
  margin-top: 2px;
  line-height: 14px;
}
</style>
